import { matchPath } from "react-router-dom";

import { clearProviderFromGlobalDataLayer } from "services/firebase/auth/analytics/events";

import { pushLastActionPayloadToDataLayer } from "seneca-common/features/analytics/gtm";
import { isUserManaged } from "seneca-common/features/user/state";

import { linkToSession } from "features/classroom/routes/links";
import { linkToSignUpInvite } from "features/sign-up/links";
import { userAccountType } from "features/user/consts";
import { MODAL_ROUTES } from "routing/routes";

import { analyticsCategories } from "../consts";
import { logGTMTrackingEvent } from "../googleTagManager";

const findSignInLocation = (): string => {
  let location = "Dashboard";

  if (
    matchPath(window.location.pathname, {
      path: MODAL_ROUTES.END_SESSION.COURSE.SECTION.path,
      exact: false,
      strict: false
    })
  ) {
    location = "End session modal";
  } else if (
    matchPath(window.location.pathname, {
      path: linkToSession(),
      exact: false,
      strict: false
    })
  ) {
    location = "Mid session modal";
  }

  return location;
};

export const analyticsLogSignIn = (state: any) => {
  logGTMTrackingEvent(
    {
      category: analyticsCategories.USER,
      event: "seneca/user/SIGN_IN",
      payload: {
        location: findSignInLocation(),
        isManaged: isUserManaged(state)
      }
    },
    state
  );
};

export const analyticsLogSignUp = (state: any) => {
  logGTMTrackingEvent(
    {
      category: analyticsCategories.USER,
      event: "seneca/user/SIGN_UP",
      payload: {
        location: findSignInLocation()
      }
    },
    state
  );
};

export const analyticsLogSignOut = () => {
  logGTMTrackingEvent({
    category: analyticsCategories.USER,
    event: "seneca/user/SIGN_OUT"
  });

  clearProviderFromGlobalDataLayer();
};

const analyticsLogCompletedStudentSecondStageSignUp = () => {
  logGTMTrackingEvent({
    category: analyticsCategories.NAVIGATION,
    event: "seneca/user/COMPLETED_STUDENT_SECOND_SIGN_UP_STAGE_SIGN_UP"
  });
};

const analyticsLogCompletedTeacherSecondStageSignUp = () => {
  logGTMTrackingEvent({
    category: analyticsCategories.NAVIGATION,
    event: "seneca/user/COMPLETED_TEACHER_SECOND_SIGN_UP_STAGE_SIGN_UP"
  });
};

const analyticsLogCompletedParentSecondStageSignUp = () => {
  logGTMTrackingEvent({
    category: analyticsCategories.NAVIGATION,
    event: "seneca/user/COMPLETED_PARENT_SECOND_SIGN_UP_STAGE_SIGN_UP"
  });
};

export const analyticsLogSkippedSecondStageSignUp = () => {
  logGTMTrackingEvent({
    category: analyticsCategories.NAVIGATION,
    event: "seneca/user/SKIPPED_SECOND_SIGN_UP_STAGE_SIGN_UP"
  });
};

export function logSignUpEventByAccountType(accountType: string) {
  switch (accountType) {
    case userAccountType.schoolteacher:
      if (
        matchPath(window.location.pathname, {
          path: linkToSignUpInvite()
        })
      ) {
        pushLastActionPayloadToDataLayer({
          "sign-up-invitee": true
        });
      }

      analyticsLogCompletedTeacherSecondStageSignUp();
      return;

    case userAccountType.schoolstudent:
      analyticsLogCompletedStudentSecondStageSignUp();
      return;

    case userAccountType.schoolparent:
      analyticsLogCompletedParentSecondStageSignUp();
      return;

    default:
      return;
  }
}

import { Client } from "@seneca/client-library";
import { QuizSessionEventDataI } from "@seneca/user-quiz-stats-service-types";

import { QuizSessionBadRequestError } from "./errors";

export default class SessionServiceClient extends Client {
  async emitQuizSession(
    quizSessionReport: Omit<QuizSessionEventDataI, "userId">
  ) {
    const url = `${this.url}/api/session/quiz`;
    const expectedResponses = [200, 400];

    const response = await this.requestMaker.makeRequest(
      url,
      {
        method: "POST",
        body: JSON.stringify(quizSessionReport)
      },
      expectedResponses
    );

    if (response.status === 400) {
      const body = response.json as unknown as {
        pointer: string;
        reason: string;
      };

      throw new QuizSessionBadRequestError(body.pointer, body.reason);
    }

    return;
  }
}

import "@seneca/senekit/out/assets/style.css";
import "normalize.css";
import "./index.scss";
import "./polyfills/index";

import { createRoot } from "react-dom/client";

import getSentry from "seneca-common/features/Sentry";

import App from "./App";
import { registerServiceWorker } from "./service-worker-registration";

try {
  // Initialise Sentry
  getSentry();
} catch (error) {
  // Do nothing, config might not be fetched and will be initialised later
}

enableMocking()
  .then(notifyCypressAppReady)
  .then(() => {
    const container = document.getElementById("root");
    const root = createRoot(container!);
    root.render(<App />);

    if (module.hot) {
      module.hot.accept("./App", () => {
        const NextApp = require("./App").default;
        root.render(<NextApp />);
      });
    }
  });

registerServiceWorker();

async function enableMocking() {
  if (process.env.NODE_ENV === "development") {
    const { msw } = await import("./mocks/window");
    msw.worker.start({ quiet: true, onUnhandledRequest: "bypass" });

    const { configureMockPersona } = await import(
      "./mocks/configureMockPersona"
    );
    configureMockPersona();

    window.msw = msw;
  }
}

async function notifyCypressAppReady() {
  if (window.Cypress) {
    window.appReady = true;
  }
}

if (process.env.NODE_ENV === "production") {
  const madRainbowFont =
    "font-weight: bold; padding-bottom: 20px; text-align: center; font-size: 40px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)";
  console.log("%cWelcome to the learning zone!", madRainbowFont);
  // TODO: add some sick Ascii art
}

// Developer debug message as it can be kind of hard to know what version your running with the PWA caching
console.log("Version: ", process.env.REACT_APP_VERSION);

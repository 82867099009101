import { List, Map } from "immutable";

import { isIn } from "seneca-common/utils/functions";

import actionTypes from "../action-types";
import moduleActionTypes from "../action-types/sessionModule";
import messageActionTypes from "../features/messages/action-types";
import Session from "../models/Session";
import SessionItem, { SESSION_ITEM_TYPES } from "../models/SessionItem";
import { getLastSessionItemId } from "../selectors/state/sessions/session";

export default function sessionReducer(
  sessions = Map<string, Session>(),
  action: Record<string, any>
): Map<string, Session> {
  if (
    action.payload &&
    action.payload.sessionId &&
    isIn(action.type, {
      ...actionTypes,
      ...moduleActionTypes,
      ...messageActionTypes
    })
  ) {
    return sessions.update(action.payload.sessionId, sesh =>
      session(sesh, action)
    );
  } else {
    return sessions;
  }
}

export const session = (
  session: Session = new Session(),
  action: Record<string, any>
): Session => {
  switch (action.type) {
    case actionTypes.NEW_SESSION:
      const newSession = new Session({
        id: action.payload.sessionId,
        courseId: action.payload.courseId,
        sectionIds: action.payload.sectionIds
          ? List(action.payload.sectionIds)
          : session.sectionIds,
        contentIds: action.payload.contentIds
          ? List(action.payload.contentIds)
          : session.contentIds,
        wordIds: action.payload.wordIds
          ? List(action.payload.wordIds)
          : session.wordIds,
        moduleIdsWhitelist: action.payload.moduleIdsWhitelist
          ? List(action.payload.moduleIdsWhitelist)
          : session.moduleIdsWhitelist,
        startTime: action.payload.startTime || null,
        startingProficiency: action.payload.startingProficiency,
        startingCourseProficiency: action.payload.startingCourseProficiency,
        startingBestScore: action.payload.startingBestScore,
        sessionType: action.payload.sessionType,
        smartLearningSuggestionReason:
          action.payload.smartLearningSuggestionReason,
        completesAssignment: action.payload.completesAssignment
      });
      return newSession;
    case actionTypes.COMPLETE_SESSION:
      const {
        timeFinished,
        endingProficiency,
        endingCourseProficiency,
        endingCourseScore,
        sessionScore,
        sectionIds
      } = action.payload;

      return session.merge({
        completed: true,
        endTime: timeFinished,
        endingProficiency,
        endingCourseProficiency,
        endingCourseScore,
        sessionScore,
        sectionIds: sectionIds ? List(sectionIds) : session.sectionIds
      });

    case moduleActionTypes.NEW_SESSION_MODULE:
      return session
        .update("items", items =>
          items.push(
            new SessionItem({
              // @ts-ignore
              type: SESSION_ITEM_TYPES.MODULE,
              id: action.payload.sessionModuleId
            })
          )
        )
        .set("focusedItemId", action.payload.sessionModuleId);

    case messageActionTypes.NEW_SESSION_MESSAGE:
      return session
        .update("items", items =>
          items.push(
            new SessionItem({
              // @ts-ignore
              type: SESSION_ITEM_TYPES.MESSAGE,
              id: action.payload.sessionMessageId
            })
          )
        )
        .set("focusedItemId", action.payload.sessionMessageId);
    case actionTypes.RECONCILE_SESSION_PROFICIENCIES:
      const { sessionId, ...proficiencies } = action.payload;
      return session.merge({ ...proficiencies });
    case actionTypes.SET_WON_MEMORY:
      return session.set("wonMemory", action.payload.wonMemory);
    case actionTypes.FOCUS_ITEM:
      return session.set("focusedItemId", action.payload.itemId);
    case actionTypes.FOCUS_LAST_ITEM:
      return session.set(
        "focusedItemId",
        getLastSessionItemId(session) ?? null
      );
    case actionTypes.UPDATE_SESSION_XP:
      return session.set("xp", action.payload.xp);
    case actionTypes.SET_SESSION_TYPE:
      return session.set("sessionType", action.payload.sessionType);
    case actionTypes.SET_SECTION_IDS:
      return session.set("sectionIds", action.payload.sectionIds);
    case actionTypes.SET_SESSION_OPTIONS:
      const { sessionId: _, ...options } = action.payload;
      return session.merge({ ...options });
    default:
      return session;
  }
};

import { Record } from "immutable";

import { AnySectionSessionStatsI } from "@seneca/stats-service-types";

export type SessionOptionsI = AnySectionSessionStatsI["options"];

export const defaultSessionOptions: SessionOptionsI = {
  hasHardestQuestionContent: false
};

export default class SessionOptions extends Record(defaultSessionOptions) {}

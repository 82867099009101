import { ServiceConfig, ServiceKey } from "./types";

let configEnv: string | undefined;

function getServices(): { [key in ServiceKey]: ServiceConfig } | undefined {
  try {
    if (
      window.Cypress ||
      process.env.NODE_ENV === "development" ||
      process.env.STORYBOOK_USE_CONFIG === "dev"
    ) {
      configEnv = "dev";
    }

    if (configEnv) {
      const config = require(`../../config.${configEnv}.json`);
      return config?.services;
    } else {
      console.warn("Attempted to use util not meant for production");
    }
  } catch (e) {
    console.warn("No config file found for services", e);
  }
}

export function url(serviceKey: ServiceKey, path: string) {
  const service = getServices()?.[serviceKey];
  if (service) {
    return `${service.protocol}://${service.host}/api/${path}`;
  }
  console.warn(
    "No service found for key,",
    serviceKey,
    "defaulting back to path"
  );

  return path;
}

export function cdnUrl(serviceKey: ServiceKey, path: string) {
  const service = getServices()?.[serviceKey];
  if (service) {
    if (service.baseCdnUrl) return `${service.baseCdnUrl}/api/${path}`;

    console.warn(
      "No CDN URL found for service,",
      serviceKey,
      "defaulting back to host"
    );
    return `${service.protocol}://${service.host}/api/${path}`;
  }
  console.warn(
    "No service found for key,",
    serviceKey,
    "defaulting back to path"
  );
  return path;
}

export function functionUrl(serviceKey: ServiceKey, functionName: string) {
  const service = getServices()?.[serviceKey];
  if (!service) {
    console.warn("No service found for key,", serviceKey);
    return undefined;
  }

  const functionUrls = service.functionURL;
  if (!functionUrls) {
    console.warn("No function URLs found for service,", serviceKey);
    return undefined;
  }

  const functionUrl = functionUrls[functionName];
  if (!functionUrl) {
    console.warn("No function URL found for function,", functionName);
    return undefined;
  }

  return functionUrl;
}

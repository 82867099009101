import { matchPath } from "react-router-dom";

import { LearningMode } from "features/classroom/state";
import { withLearningModeQueryParams } from "features/classroom/utils/withLearningModeQueryParams";
import { ROUTES } from "routing/routes";

import { linkToClassroomSection } from "../../links";

export function linkToSession(courseId?: string, sectionId?: string) {
  return `${linkToClassroomSection(courseId, sectionId)}/session`;
}

export function linkToStartSession(courseId?: string, sectionId?: string) {
  return `${linkToSession(courseId, sectionId)}/start`;
}

export function matchSessionPath(pathname: string) {
  const standardSession = matchPath(pathname, {
    path: linkToSession(),
    exact: false
  });

  const multiSectionSession = matchPath(pathname, {
    path: ROUTES.CLASSROOM.COURSE.MULTI_SECTION.SESSION.path,
    exact: false
  });

  const vocabularySession = matchPath(pathname, {
    path: ROUTES.CLASSROOM.COURSE.VOCABULARY.SESSION.path,
    exact: false
  });

  return standardSession || multiSectionSession || vocabularySession;
}

export const teacherPreviewKey = "teacherPreview";

export function linkToStartSessionWithPreview(
  courseId?: string,
  sectionId?: string
) {
  const params = new URLSearchParams();
  params.append(teacherPreviewKey, true as unknown as string);
  return `${linkToStartSession(courseId, sectionId)}?${params}`;
}

export const linkToStartSessionWithLearningMode = (
  courseId?: string,
  sectionId?: string,
  // @ts-ignore optional-arg
  mode: LearningMode | string
) =>
  withLearningModeQueryParams(
    linkToStartSession(courseId, sectionId),
    mode as LearningMode
  );

export const linkToStartAssignmentSessionWithAssignmentId = (
  courseId: string,
  sectionId: string,
  assignmentId: string
) =>
  `${withLearningModeQueryParams(
    linkToStartSession(courseId, sectionId),
    LearningMode.assignments
  )}&assignmentId=${assignmentId}`;

export const linkToStartDefaultLearningModeSession = (
  courseId: string,
  sectionId?: string
) =>
  linkToStartSessionWithLearningMode(courseId, sectionId, LearningMode.default);

export const linkToStartWrongAnswersSession = (
  courseId: string,
  sectionId: string
) =>
  linkToStartSessionWithLearningMode(
    courseId,
    sectionId,
    LearningMode.wrongAnswers
  );

export const linkToStartSessionAsChallengee = (
  courseId: string,
  sectionId: string,
  score: string
) => {
  return `${linkToStartSession(courseId, sectionId)}?score=${score}`;
};

export const linkToStartQuizSession = (courseId: string, sectionId: string) =>
  linkToStartSessionWithLearningMode(courseId, sectionId, LearningMode.quiz);
